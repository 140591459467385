import React from 'react';
import ProjectBox from './components/ProjectBox';

const projects = [
  { id: 1, title: 'Blog', image: '/images/blog_cover.png', link: 'https://world.hey.com/haws' },
  { id: 2, title: 'Utah Product Guild', image: '/images/upg.png', link: 'https://utahproductguild.com' },
  { id: 3, title: 'Product Principles', image: '/images/principles.png', link: 'https://world.hey.com/haws/how-i-do-product-9d3b3bb8' },
  { id: 4, title: 'AI Articles', image: '/images/ai_writing.png', link:'https://books.deepend.tech/3/a-product-manager-s-thoughts-on-ai' },
  { id: 5, title: 'AI Explainability Cards', image: '/images/ai_explain.png', link: 'https://ai-explainability-cards.replit.app' },
  {id: 6, title: 'Text-to-Speech App', image: '/images/speak_easy.png', link: 'https://speak-easy.replit.app' },
  {id: 7, title: 'Audio Blogs', image: '/images/audioblog.png', link: 'https://haws.notion.site/Audio-Blog-Posts-26d29fb160d1421cb7bf4bf252589347' },
  { id: 8, title: 'Structured Outputs Research', image: '/images/structured_outputs.png', link:'https://haws.notion.site/Structured-Outputs-Research-10426fece2cc808db1dfeacc9437d000?pvs=4' },
  {id: 9, title: 'AI Ops Research', image: '/images/aiops.png', link: 'https://haws.notion.site/AIOps-Research-82990d59e9dd4bddb41ef1a3e920eb1a' },
  {id: 10, title: 'Chat With My Resume', image: '/images/resume.png', link: 'https://brayden-resume-bot.replit.app' },
  {id: 11, title: 'PM Resources', image: '/images/bible.png', link: 'https://haws.gumroad.com/l/pmbible' },
  {id: 12, title: 'No-Code Forecasting', image: '/images/forecasting.png', link: 'https://app.hex.tech/455658aa-ee04-480f-945a-3fd455933fa2/app/6356acb1-2d8d-4f12-96a9-376dac1bb85e/latest' },
  {id: 13, title: 'AI Database Tool', image: '/images/aidatabase.png', link: 'https://app.hex.tech/455658aa-ee04-480f-945a-3fd455933fa2/app/5674db16-661a-4545-916f-ffafe6620ea2/latest' },
  {id: 14, title: 'GitHub Projects', image: '/images/github.png', link: 'https://github.com/brayden-s-haws' },
  {id: 15, title: 'Replit Projects', image: '/images/replit.png', link: 'https://replit.com/@braydenhaws' },
  {id: 16, title: 'My Truck', image: '/images/truck.png', link: 'https://haws.notion.site/Truck-Project-421d28a7801443d5a0d4cecca9163748'},
  {id: 17, title: 'Hikes', image: '/images/hikes.png', link: 'https://haws.notion.site/Hikes-539234d4e1314ec9b6292645d259e0df'},
  {id: 18, title: 'Connect on LinkedIn', image: '/images/linkedin.png', link: 'https://www.linkedin.com/in/braydenh/'}
];

function App() {
  return (
    <div className="min-h-screen bg-gray-950 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center mb-12">
          <img 
            src="/images/COVATAR.png" 
            alt="Brayden Haws" 
            className="w-36 h-36 rounded-full mb-6 border-4 border-earthy-brown"
          />
          <h1 className="text-5xl font-semibold text-earthy-brown mb-3 text-center">BRAYDEN HAWS</h1>
          <p className="text-xl text-gray-400 text-center max-w-2xl">Product Manager & AI Builder</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map(project => (
            <ProjectBox key={project.id} {...project} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;