import React from 'react';

function ProjectBox({ title, image, link }) {
  return (
    <a 
      href={link} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="block bg-gray-900 rounded-lg shadow-md overflow-hidden flex flex-col transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl border border-gray-700"
    >
      <div className="aspect-w-16 aspect-h-9 bg-gray-800">
        <div className="w-full h-full flex items-center justify-center p-2">
          <img 
            src={image} 
            alt={title} 
            className="max-w-full max-h-full object-contain"
          />
        </div>
      </div>
      <div className="p-3 bg-gray-900 flex-grow flex items-center justify-center">
        <h2 className="text-lg font-semibold text-gray-400 line-clamp-2 text-center">{title}</h2>
      </div>
    </a>
  );
}

export default ProjectBox;